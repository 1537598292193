// /* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-trailing-spaces */
/* eslint-disable arrow-body-style */
/* eslint-disable newline-before-return */
/* eslint-disable no-unused-vars */
/* eslint-disable func-names */
/* eslint-disable prefer-template */
/* eslint-disable no-param-reassign */
/* eslint-disable arrow-parens */

import Vue from 'vue'
import axios from 'axios'

// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
// axios.defaults.headers.common['Authorization'] = '';
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

const config = {
  // baseURL: process.env.baseURL || process.env.apiUrl || ""

  // baseURL: 'http://localhost:8080/api/',

  baseURL: 'https://sekolah.smpxaverius1bl.com/api/'
  // timeout: 60 * 1000, // Timeout
  // withCredentials: true, // Check cross-site Access-Control
}

// eslint-disable-next-line no-underscore-dangle
const _axios = axios.create(config)

_axios.interceptors.request.use(
  // eslint-disable-next-line arrow-parens
  // eslint-disable-next-line no-shadow
  (config) => {
    // Do something before request is sent
    config.headers.Authorization = 'Bearer ' + localStorage.getItem('apiToken')
    // eslint-disable-next-line semi
    return config;
  },
  (error) => {
    // Do something with request error
    return Promise.reject(error)
  },
)

// Add a response interceptor
_axios.interceptors.response.use(
  (response) => {
    // Do something with response data

    if(response.data.valid == false){
      window.location.assign("/login");      
    }

    return response
  },
  (error) => {
    // Do something with response error
    return Promise.reject(error)
  },
)
// eslint-disable-next-line no-unused-vars
// eslint-disable-next-line no-shadow
Plugin.install = function (Vue, options) {
  Vue.axios = _axios
  window.axios = _axios
  Object.defineProperties(Vue.prototype, {
    axios: {
      get() {
        return _axios
      },
    },
    $axios: {
      get() {
        return _axios
      },
    },
  })
}

Vue.use(Plugin)

export default Plugin
